import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import BackgroundMediaDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/BackgroundMediaDataTransformerBlock';
import { styled } from '@activebrands/core-web/libs/styletron';

const HeroWrapper = styled('section', ({ $isGrid }) => ({
    width: '100%',
    height: '100vh',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
}));

const ContentWrapper = styled('section', {
    aspectRatio: 'var(--ratio-vertical-primary)',
    marginBottom: 'var(--margin-bottom-module)',
    width: '100%',
    maxHeight: '100vh',
    height: '100%',

    [media.min['tablet.sm']]: {
        aspectRatio: 'var(--ratio-square)',
    },

    [media.min['desktop.sm']]: {
        aspectRatio: 'var(--ratio-horizontal-primary)',
    },
});

const BackgroundMediaModule = ({ isGrid = false, isHero = false, ...rest }) => {
    if (isHero) {
        return (
            <HeroWrapper $isGrid={isGrid}>
                <BackgroundMediaDataTransformerBlock isHero {...rest} />
            </HeroWrapper>
        );
    }

    return (
        <ContentWrapper>
            <BackgroundMediaDataTransformerBlock {...rest} />
        </ContentWrapper>
    );
};

BackgroundMediaModule.propTypes = {
    isGrid: PropTypes.bool,
    isHero: PropTypes.bool,
};

export default BackgroundMediaModule;
